import React, { FunctionComponent } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

interface IPopupProps {
  title: string
  description: string
  isOpen: boolean
  onClose: () => void
}

export const Popup: FunctionComponent<IPopupProps> = ({
  title,
  description,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom="1.5rem">{description}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default Popup
