import React, { FunctionComponent, useEffect, useState } from "react"
import { Box, Heading, Text } from "@chakra-ui/layout"
import { Flex, UnorderedList, ListItem, Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import { disableGoogleAnalytics } from "../utils/disableGoogleAnalytics"
import Popup from "../components/Popup"
import { colors } from "../theme/theme"

const StyledText = styled(Text)`
  margin: 0.5rem 0;
`

export const Privacy: FunctionComponent = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false)

  return (
    <>
      <Layout disableCookiePopup={true}>
        <Seo
          title="Privacy Policy | hendrikgruber.com"
          meta={[
            {
              name: "Privacy Policy",
              content: "Privacy Policy for hendrikgruber.com",
            },
          ]}
        />

        <Flex width="100%" flexDir="column">
          <Box width="100%">
            <Heading as="h1">Privacy Policy</Heading>
          </Box>
          <Box width="100%" marginTop="2rem">
            <StyledText>
              At hendrikgruber.com, accessible from hendrikgruber.com, one of my
              main priorities is the privacy of my visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by hendrikgruber.com and how it is used.
            </StyledText>
            <StyledText>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact me.
            </StyledText>
            <StyledText>
              This Privacy Policy applies only to online activities and is valid
              for visitors to the website with regards to the information that
              they shared and/or collect at hendrikgruber.com. This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </StyledText>
            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              Consent
            </Heading>
            <StyledText>
              By using this website, you hereby consent to the Privacy Policy
              and agree to its terms.
            </StyledText>
            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              Collected Information
            </Heading>
            <StyledText>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </StyledText>
            <StyledText>
              If you contact me directly, I may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </StyledText>
            <StyledText>
              When you register for my newsletter, you may be asked for your
              contact information, including items such as an email address. The
              newsletter address list for hendrikgruber.com is managed via{" "}
              <a
                href="https://mailchimp.com/"
                target="_blank"
                style={{ color: colors.blue.blue2 }}
              >
                Mailchimp
              </a>
              . These mail addresses are not used for any other purpose other
              than sending newsletters. No further third parties have, or are
              given, access to the list of mail addresses used for the
              newsletter. If you wish to unsubscribe from the newsletter, then
              there is a link at the bottom of each newsletter Email allowing
              you to do so.
            </StyledText>
            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              How you information is used
            </Heading>

            <UnorderedList>
              <ListItem>Provide, operate, and maintain our website</ListItem>
              <ListItem>Improve, personalize, and expand our website</ListItem>
              <ListItem>
                Understand and analyze how users use this website
              </ListItem>
              <ListItem>
                Develop new products, services, features, and functionality
              </ListItem>
            </UnorderedList>

            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              Cookies and Google Analytics
            </Heading>
            <StyledText>
              Hendrikgruber.com follows a standard procedure of using Google
              Analytics and cookies. All hosting Other than potential required
              cookies, this website, unless opted out, saves cookeis for the
              purpose of tracking analytics. The purpose of the information is
              for analyzing trends, administering the site, tracking users'
              movement on the website, and gathering demographic information.
              This helps to improve the website by allowing usage analysis.
            </StyledText>
            <StyledText>
              Disallowing the usage of such cookies sets a new cookie named
              "ga-disable-G-PSK69LF61L" with the value "true". This disables
              Google Analytics from tacking any of your information while on the
              website.
            </StyledText>
            <StyledText>
              If you do not wish to allow the cookies required for Google
              Analytics, you can opt out here
              <Button
                onClick={() => {
                  setShowPopup(disableGoogleAnalytics(true))
                }}
                style={{
                  margin: "0 1rem",
                  padding: "0.2rem",
                  fontWeight: "normal",
                  fontSize: "0.8rem",
                  height: "1.2rem",
                }}
              >
                Opt out
              </Button>
              <Popup
                title="Disabled"
                description="Google Analytics is now disabled."
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
              />
            </StyledText>
            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              Google DoubleClick DART Cookie
            </Heading>
            <StyledText>
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to www.website.com and other sites on the
              internet. However, visitors may choose to decline the use of DART
              cookies by visiting the Google ad and content network Privacy
              Policy at the following URL –{" "}
              <Link
                to="https://policies.google.com/technologies/ads"
                title="Google Ads Policy"
                target="_blank"
              >
                https://policies.google.com/technologies/ads
              </Link>
            </StyledText>
          </Box>
        </Flex>
      </Layout>
    </>
  )
}

export default Privacy
